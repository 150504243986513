<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>设备列表</p>
        <el-button type="primary" class="btn" style="width: auto;margin-inline-start: auto;"
          @click="dialogFormVisibleMb = true" width="250"> 学校锻炼模板 </el-button>
        <el-button type="primary" class="btn" style="width: auto;"
          @click="dialogFormVisibleygy = true" width="250"> 业务设置 </el-button>
        <el-button type="primary" class="btn" @click="dialogFormVisible = true">+ 绑定设备</el-button>
      </div>
      <div class="query">
        <div class="queryLeft" style="width: auto;">
          <div style="width: 26%;">编&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号：
            <el-input class="input" v-model="serchList.mechineName" size="medium" placeholder="请输入编号">
            </el-input>
          </div>

          <div style="width: 25%;">设备类型：
            <!--            <el-select class="select" v-model="serchList.MachineType" @change="machineList(serchList,1)" size="medium"
              placeholder="请选择设备类型"> -->
            <el-select class="select" v-model="serchList.MachineType" size="medium" placeholder="请选择设备类型">
              <el-option v-for="item in facilityList" :key="item.value" :label="item.info" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- <div>地区：
						<el-select class="select" v-model="serchList.region" @change="machineList(serchList,1)"
							size="medium" placeholder="请选择地区">
							<el-option v-for="item in regionList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div> -->

          <div style="width: 25%;">选择机构：
            <!--            <el-select class="select" v-model="serchList.yxtype" @change="machineList(serchList,1)" size="medium"
                        placeholder="请选择状态"> -->
            <el-select class="select" v-model="serchList.jgType" size="medium" placeholder="请选择状态" @change="jgChange">
              <el-option v-for="item in jgList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div style="width: 25%;" v-if="serchList.jgType ==1">学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;校：
            <!--            <el-select class="select" v-model="serchList.schoolId" @change="machineList(serchList,1)" size="medium"
              placeholder="请选择学校"> -->
            <el-select class="select" v-model="serchList.schoolId" size="medium" filterable placeholder="请选择" @change="xxChange">
              <el-option v-for="item in schoolData" :key="item.id" :label="item.schoolName" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div style="width: 25%;" v-if="serchList.jgType ==2">门&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;店：
            <!--            <el-select class="select" v-model="serchList.schoolId" @change="machineList(serchList,1)" size="medium"
              placeholder="请选择学校"> -->
            <el-select class="select" v-model="serchList.shopId" size="medium" filterable placeholder="请选择"  @change="shopChange">
              <el-option v-for="item in shopData"  :key="item.id" :label="item.schoolName" :value="item.id">
              </el-option>
            </el-select>
          </div>
         <div style="width: 25%;" v-if="serchList.jgType ==3">自&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;助：

            <el-select class="select" v-model="serchList.shopId" size="medium" filterable placeholder="请选择" @change="zzdChange">
              <el-option v-for="item in shopData" :key="item.id"  :label="item.schoolName" :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div style="width: 26%;">投放状态：
            <!--            <el-select class="select" v-model="serchList.tftype" @change="machineList(serchList,1)" size="medium"
              placeholder="请选择状态"> -->
            <el-select class="select" v-model="serchList.tftype" size="medium" placeholder="请选择状态">
              <el-option v-for="item in tfList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="width: 25%;">运行状态：
            <!--            <el-select class="select" v-model="serchList.yxtype" @change="machineList(serchList,1)" size="medium"
                        placeholder="请选择状态"> -->
            <el-select class="select" v-model="serchList.yxtype" size="medium" placeholder="请选择状态">
              <el-option v-for="item in yxList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="queryRight" style="width: 20%;align-self: flex-start;">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="search(serchList)">查询</el-button>
        </div>
      </div>
      <el-table :data="facilityInfos" v-loading="loading">
        <el-table-column prop="MechineName" label="设备编号" align="center" width="150"></el-table-column>
        <el-table-column label="绑定机构"  prop="schoolName" align="center"></el-table-column>
        <el-table-column label="设备类型" :formatter="machineFilter" align="center"></el-table-column>
        <el-table-column label="IP" prop="ip"  align="center"></el-table-column>
        <!-- 				<el-table-column label="状态" sortable :formatter="Status" align="center"></el-table-column> -->
        <el-table-column label="在线状态" :formatter="onlineStatus" align="center" width="180">
          <template slot-scope="scope">
            <div style="display: flex;align-items: center;justify-content: center;">
              <div style="width: 10px;height: 10px;border-radius: 50%;margin-right: 10px;"
                :style="scope.row.onlineStatus == '1' ? 'background: #67C23A;' : scope.row.onlineStatus == '2'?'background: #F56C6C;': ''">
              </div>
              <span v-if="scope.row.onlineStatus == '1'">运行中</span>
              <span v-else-if="scope.row.onlineStatus == '2'">离线</span>
              <span v-else></span>
            </div>
            <div v-if="scope.row.onlineStatus == '2' && scope.row.onlineTime > 0">{{$util.dateFormat(scope.row.onlineTime)}}</div>
          </template>
        </el-table-column>
        <el-table-column label="运行时间" prop="hour" width="100" align="center"></el-table-column>
        <!-- 				<el-table-column label="最后时间" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.onlineTime != '0'"
							style="display: flex;align-items: center;justify-content: center;">
							<div style="width: 10px;height: 10px;border-radius: 50%;margin-right: 10px;"
								:style="scope.row.onlineStatus == '1' ? 'background: #67C23A;' : 'background: #F56C6C;'">
							</div>
							<div>{{$util.dateFormat(scope.row.onlineTime)}}</div>
						</div>
					</template>
				</el-table-column> -->
        <el-table-column label="投放时间"  width="160" :formatter="LaunchTime" align="center"></el-table-column>
        <el-table-column label="绑定时间"  width="160" :formatter="InsertTime" align="center">
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="300">
          <template slot-scope="scope">

            <el-button size="mini" v-if="scope.row.SchoolID ==0" type="primary" plain
              @click="relieveBind(scope.$index, scope.row)" style="margin-left: 10px;">解除绑定</el-button>
            <el-button size="mini" v-else type="primary" plain style="display: none;">解除绑定</el-button>

            <el-button size="mini" v-if="scope.row.SchoolID !=0" type="primary" plain
              @click="recycle(scope.$index, scope.row)">回收设备</el-button>
            <el-button size="mini" v-else type="primary" plain style="display: none;" plain>回收设备</el-button>

<!--            <el-button size="mini" v-if="scope.row.Status ==1" type="primary" plain
              @click="machineStartFun( scope.row)">监控设备</el-button>
            <el-button size="mini" v-if="scope.row.Status ==2" type="primary" plain
              @click="machineStartFun( scope.row)">不监控设备</el-button> -->
	
            <el-button v-if="scope.row.MachineType==9" size="mini" type="primary" plain @click="cameraAdmin(scope.row.ID)">摄像头管理</el-button>
            <el-button size="mini" type="primary" plain @click="detail(scope.$index, scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total,prev, pager, next, jumper" :current-page.sync="currpage" :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="添加设备" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
      <el-form :model="Addfacility" ref="articleForm" style="width:460px;margin:0 auto">
        <el-form-item label="请输入设备编码">
          <el-input v-model="Addfacility.facility" style="width: 260px; float: left" placeholder="请输入设备编码" clearable>
          </el-input>
        </el-form-item>
        <el-form-item style="margin-top:5px">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="Adds">添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="业务设置" :visible.sync="dialogFormVisibleygy" top="50px" width="500px"
      :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" status-icon  label-width="160px" class="demo-ruleForm">
        <!-- <el-form-item label="设备密码" prop="pass" style="width: fit-content;"> -->
         <el-form-item label="设备密码"  style="width: fit-content;" >
          <el-input  v-model.trim="ruleForm.pass" clearable placeholder="纯数字(不填不修改密码)"> </el-input>
        </el-form-item>

        <el-form-item label="跨店锻炼"  style="width: fit-content;">
          <el-select class="select" v-model="ruleForm.kd" size="medium" placeholder="请选择状态" >
            <el-option v-for="item in kdList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item style="margin-right: 100px;">
           <el-button @click="dialogFormVisibleygy = false">取消</el-button>
           <el-button type="primary" @click="submitForm(1)">提交</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>

    <el-dialog title="学校锻炼模板" :visible.sync="dialogFormVisibleMb" top="50px" width="500px"
      :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" status-icon  label-width="160px" class="demo-ruleForm">
        <el-form-item label="学校锻炼仪有无红光"  style="width: fit-content;">
          <el-select class="select" v-model="ruleForm.hg" size="medium" placeholder="请选择状态" >
            <el-option v-for="item in hghaveList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="ruleForm.hg == 1" label="学校锻炼仪红光时长"  style="width: fit-content;">
          <el-select class="select" v-model="ruleForm.hg_time" size="medium" placeholder="请选择状态" >
            <el-option v-for="item in hgtimeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学校锻炼仪时长"  style="width: fit-content;">
          <el-select class="select" v-model="ruleForm.dly_time" size="medium" placeholder="请选择状态" >
            <el-option v-for="item in dlyList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item style="margin-right: 100px;">
           <el-button @click="dialogFormVisibleygy = false">取消</el-button>
           <el-button type="primary" @click="submitForm(2)">提交</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>


    <el-dialog title="设备回收" :visible.sync="Recycledialog" top="30px">
      <span>确认是否回收设备?</span>
      <br />
      <br />
      <span>
        <p style="color:#1890FF;display:inline">设备编号：{{machine.MechineName}}</p>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Recycledialog = false">取 消</el-button>
        <el-button type="primary" @click="affirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- <el-dialog title="解除绑定" :visible.sync="relieve" top="30px" :close-on-click-modal="false">
      <span>是否确认解除编号<p style="color:#1890FF;display:inline">{{facilityID}}</p>的设备</span>
      <div class="tels">
        <p>请输入您的手机号：</p>
        <el-input v-model.number="Moblie" style="width: 300px;" placeholder="请输入手机号" maxlength="11">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="relieve = false">取 消</el-button>
        <el-button type="primary" @click="affirmbd">下一步</el-button>
      </span>
    </el-dialog>

    <el-dialog title="解除绑定" :visible.sync="verification" top="30px" :close-on-click-modal="false">
      <span>手机<p style="color:#1890FF;display:inline">{{Phone}}</p></span>
      <div class="codies">
        <p>请输入验证码：</p>
        <el-input v-model="Checkcode" style="width: 300px;" placeholder="请输入验证码"></el-input>
        <span class="sendyzm" @click="sendYZM" v-show="show">发送验证码</span>
        <span class="sendyzm" v-show="!show" style="color: #cfcfcf">
          {{ count + "秒" + "后重发"}}
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="verification = false">取 消</el-button>
        <el-button type="primary" @click="removeBind">解除绑定</el-button>
      </span>
    </el-dialog> -->
		
		<el-dialog title="解除绑定" :visible.sync="relieve" top="30px" :close-on-click-modal="false">
		  <span>是否确认解除编号<p style="color:#1890FF;display:inline">{{facilityID}}</p>的设备</span>
		  <div class="tels">
		   
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="relieve = false">取 消</el-button>
		    <el-button type="primary" @click="removeBind">确定</el-button>
		  </span>
		</el-dialog>

    <!--    <div style="height: 300px;">
      <el-steps direction="vertical" :active="this.yxList.length">
        <el-step v-for="(item,index) in yxList" :key=index :title="item.label" @click.native="aaa(item.value)"  >
        </el-step>
      </el-steps>
    </div>
 -->
  </div>
</template>

<script>
  import {
    machineList,
    machineBind,
    machineTypeList,
    schoolList,
    schoolRecoveryMachine,
    machineRelieve,
    agentOptomPwd,
    machineStart,
    shopList,
    agentHgList
  } from '@/api/agentApi.js'
  import {
    sendSmsNew,
  } from '@/api/TeacherApi.js'

  export default {
    data() {

      var checkLpass = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('登录密码不能为空'));
        } else {
          callback();
        }
      };

      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else if (value.length < 6 || value.length > 16) {
          callback(new Error('密码长度再6-16位'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };

      return {
        ruleForm: {
          pass     : '',
          kd       : 1,
          hg       : 1,
          hg_time  : '3',
          dly_time : "10",
        },
        rules: {
          pass: [{
            validator: validatePass,
            trigger: 'blur'
          }],
          checkPass: [{
            validator: validatePass2,
            trigger: 'blur'
          }],
          lpass: [{
            validator: checkLpass,
            trigger: 'blur'
          }]
        },
        facilityInfos: [], // 设备列表数据
        yxList: [{
          value: '0',
          label: '全部'
        }, {
          value: '1',
          label: '运行中'
        }, {
          value: '2',
          label: '离线'
        }], // 设备运行状态
        tfList: [{
          value: '0',
          label: '全部'
        }, {
          value: '1',
          label: '已投放'
        }, {
          value: '2',
          label: '未投放'
        }], // 设备投放状态
        kdList: [{
          value: 1,
          label: '允许'
        }, {
          value: 0,
          label: '不允许'
        }], // 运行跨度
        hghaveList: [{
          value: 1,
          label: '有'
        }, {
          value: 0,
          label: '无'
        }], // 有无红光

        dlyList: [{
          value: '10',
          label: '10分钟'
        }, {
          value: '15',
          label: '15分钟'
        },
        {
          value: '20',
          label: '20分钟'
        },
        {
          value: '25',
          label: '25分钟'
        },
        {
          value: '30',
          label: '30分钟'
        },
        {
          value: '35',
          label: '35分钟'
        },
        {
          value: '40',
          label: '40分钟'
        }], // 锻炼时长
        hgtimeList: [], // 红光时长数组
        dialogFormVisible: false, // 添加设备弹窗
        dialogFormVisibleygy: false, // 修改验光仪弹窗
        dialogFormVisibleMb: false, // 修改模板弹窗
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        loading: false, //加载框
        serchList: {
          shopId:'',
          jgType:'',
        }, //查询列表
        schoolData: [], //学校集合
        shopData: [], //门店集合
        facilityList: [], //设备类型
        stateList: [ //状态集合
          {
            value: 1,
            label: '全部设备'
          }, {
            value: 2,
            label: '空闲设备'
          }, {
            value: 3,
            label: '非空闲设备'
          }
        ],
        regionList: [], //地区集合
        Addfacility: {
          facility: '' // 添加的设备编码
        },
        Addfacilityygy: {
          password: '', // 验光仪密码
          passwordconfirm: '', // 验光仪确认密码
          loginpassword: '' // 登录密码
        },
        searchJsonCopy: {},
        machine: {},
        Recycledialog: false, // 回收设备弹窗
        serial: '', // 设备编码
        facilityID: '', // 设备编号
        relieve: false, // 解除绑定发送短信弹窗
        ActiveTel: '', // 当前账号手机号
        relieve: false, // 解除绑定发送短信弹窗
        Moblie: '', // 手机号
        Phone: '', // 手机号
        timer: null, // 验证码标识
        count: '',
        show: false, // 倒计时(秒)
        Checkcode: '', // 验证码
        verification: false, // 验证码弹窗
        regex: /^1[3456789]\d{9}$/, // 手机号正则
        ActiveTel: '' ,// 当前账号手机号
        jgList: [{
          value: '1',
          label: '学校'
        }, {
          value: '2',
          label: '门店'
        },
        {
          value: '3',
          label: '自助点'
        },
        ], // 机构列表

      }
    },
    created() { //created：在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图
      if (JSON.parse(localStorage.getItem('searchJsonCopy'))) {
        this.serchList = JSON.parse(localStorage.getItem('searchJsonCopy'));
        // this.currpage = parseInt(localStorage.getItem('fpage'))
        localStorage.removeItem('searchJsonCopy')
      } else {
        this.serchList = {};
        this.currpage = this.$router.currentRoute.query.page ? Number(this.$router.currentRoute.query.page) : 1 //获取url的参数
      }

    },
    mounted() {
      this.machineList(this.serchList, this.currpage); // 设备列表
      this.schoolList(); // 获取学校列表
      this.shopList(); // 获取门店列表
      this.getMachineTypeList(); //获取设备类型列表
      this.agentHgListFun(); //获取红光时长列表
      const newMoblie = JSON.parse(localStorage.getItem('userInfo'))
      this.ActiveTel = newMoblie.tel // 当前账号手机号
      //console.log(newMoblie);
      // this.ActiveTel = 15164046768 // 测试当前账号手机号 上线要注释 开启上面的
    },
    methods: {
			//摄像头管理
			cameraAdmin(id) {
				this.$router.push({
					name: 'cameraList',
					params: {
						machineId: id
					}
				})
			},
      reset() { //重置按钮
        this.serchList = {}
        this.serchJsonCopy = {}
        this.jgType = ''
        this.machineList({}, 1)
      },
      LaunchTime(row, column) { // 投放时间过滤
        return row.LaunchTime
        // return row.LaunchTime.slice(0, 16)
      },
      InsertTime(row, column) { // 绑定时间过滤
        // return row.InsertTime.slice(0, 16)
        return row.InsertTime
      },
      search(obj){
        this.searchJsonCopy = JSON.parse(JSON.stringify(obj))//深拷贝
        this.machineList(obj,1)
      },
      machineList(serchJson, currpage) { // 设备列表
        //console.log(localStorage.getItem('searchJsonCopy'))
        this.loading = true;
        const data = serchJson;
        data["page"] = currpage; //	当前页数
        data["limit"] = this.pagesize; // 每页条数
        //this.$router.push({query:{page:currpage,searchJsonCopy:serchJson}})//查询传url参数
        machineList(data).then(res => {
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.facilityInfos = res.data.List // 设备列表
          this.currpage = parseInt(currpage) //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      schoolList() { // 学校列表
        const data = {}
        data["page"] = 1 //	当前页数
        data["limit"] = 50 // 每页条数
        data["stype"] = 1 // 类型
        data["list"] = 1 // 每页条数
        schoolList(data).then(res => {
          this.schoolData = res.data.List.map((item, index) => {
            return Object.assign({}, {
              'schoolName': item.SchoolName
            }, {
              'id': item.ID
            })
          })
        }).catch(err => {
          console.log(err)
        })
      },
      shopList() { // 门店列表
        const data = {}
        data["page"] = 1 //	当前页数
        data["limit"] = 100 // 每页条数
        shopList(data).then(res => {
          this.shopData = res.data.data.map((item, index) => {
            return Object.assign({}, {
              'schoolName': item.shop_name
            }, {
              'id': item.id
            })
          })

          this.shopData.unshift({
          	schoolName: '全部门店',
          	id: 0
          })
        }).catch(err => {
          console.log(err)
        })
      },
      agentHgListFun() { // 门店列表
        agentHgList().then(res => {
          this.hgtimeList = res.data.map(item => {
            return {
              value: item.id,
              label: item.arg_val+'分钟',
            }
          });
        }).catch(err => {
          console.log(err)
        })
      },
      async getMachineTypeList() { //获取设备类型列表
        let res = await machineTypeList(); //请求接口
        this.facilityList = res.data; //设备类型列表
      },
      BindSchool(row, column) { // 绑定学校
        //return row.schoolName == null ? '暂无' : row.schoolName
        // console.log(row);
      },
      onlineStatus(row) { //在线状态过滤
        return row.onlineStatus == '1' ? '在线' : row.onlineStatus == '2' ? '离线' : '';
      },
      Status(row, column) { // 状态转换
        return row.Status == '1' ? '运行中' : row.Status == '2' ? '停用中' : '故障中';
      },
      machineFilter(row, column) { //设备类型过滤
        switch (row.MachineType) {
          case '1':
            return '视力检测设备'
            break;
          case '4':
            return '校门安防设备'
            break;
          case '5':
            return '验光仪设备'
            break;
          case '6':
            return '音视频设备'
            break;
          case '7':
            return '训练仪设备'
            break;
          case '8':
            return '签到仪设备'
            break;
          case '9':
            return '校园签到'
            break;
					case '10':
						return '读卡器'
						break;
          default:
            return '未设置'
        }
      },
      detail(index, row) { // 点击详情
        localStorage.setItem('searchJsonCopy', JSON.stringify(this.searchJsonCopy));
        localStorage.setItem('fpage', parseInt(this.currpage));
        localStorage.setItem('Route', 'facility') // 存跳转标识

        var path = row.MachineType == 1 ? 'facilitydetail' : row.MachineType == 4 ? 'facilitydetailXyws' : row
          .MachineType == 5 ? 'facilitydetailXyws' : row.MachineType == 6 ? 'facilitydetailXyws' : '';

        this.$router.push({
          name: path,
          params: {
            detailInfo: row.ID,
            type: row.MachineType,
          },
        })
      },
      Adds() { // 点击添加(添加设备弹窗)
        if (this.Addfacility.facility == '') {
          this.$message('设备编码不能为空')
        } else {
          const data = {
            mechineCode: this.Addfacility.facility
          }
          machineBind(data).then(res => {
            if (res.status.code == 1) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.Addfacility.facility = '' // 清空
              this.serchList = {}
              this.machineList({}, 1) // 刷新
              this.dialogFormVisible = false; // 刷新
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      },
      handleCurrentChange(val) { // 分页
        this.machineList(this.searchJsonCopy, val)
        this.$router.push({query:{page:val,pageaa:val}})//分页传url参数
        // this.currpage = val
      },
      recycle(index, row) { // 点击回收
        this.Recycledialog = true; // 打开设备回收弹窗
        this.machine = row //设备赋值
      },
      affirm() { // 回收设备的确定
        const data = {
          schoolId: this.machine.SchoolID, // 学校ID
          mechineId: this.machine.ID, // 设备ID
          mechineType: this.machine.MachineType //设备类型
        }

        schoolRecoveryMachine(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '回收成功',
              type: 'success'
            })
            this.Recycledialog = false // 关闭弹窗
            if (this.facilityInfos.length == 1 && this.searchJsonCopy.schoolId > 0) {
              if (this.currpage == 1) {} else {
                this.currpage = this.currpage - 1
              }
            }

            this.machineList(this.searchJsonCopy, this.currpage) // 刷新学校设备列表
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      relieveBind(index, row) { // 解除绑定
        this.relieve = true // 打开弹窗
        // console.log(row.MechineCode);
        this.facilityID = row.MechineName // 设备编号赋值
        this.serial = row.MechineCode // 设备编码赋值
      },
      affirmbd() { // 点击下一步
        let NewMoblie = this.Moblie
        NewMoblie += ''
        const NewTel = NewMoblie.replace(NewMoblie.substring(3, 7), '****')
        this.Phone = NewTel

        const data = {
          phone: this.Moblie,
          type: 3 //	短信类型 ( 1老师修改手机号 3代理解除设备绑定 )
        }

        if (!this.regex.test(this.Moblie)) {
          this.$message('手机号输入有误')
        } else if (this.Moblie != this.ActiveTel) { //当前手机账号和当前账号手机账号
          this.$message('当前手机号输入有误')
        } else {
          //this.verification = true // 打开验证码弹窗
          // //原来发短信sendSms(data).then(res => {
          sendSmsNew(data).then(res => {
            if (res.status.code == 1) {
              this.relieve = false
              this.verification = true // 打开验证码弹窗
              this.YZMsend()
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      },
      removeBind() { // 点击解除绑定
        const data = {
          mechineCode: this.serial, // 设备编码
          mechineNum: this.facilityID, // 设备编号
          // code: this.Checkcode, //	验证码
          // phone: this.ActiveTel, //	手机号
        }

        machineRelieve(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '解绑成功',
              type: 'success'
            })
            this.Moblie = '' // 清空
            this.relieve = false

            if (this.facilityInfos.length == 1) {
              if (this.currpage == 1) {} else {
                this.currpage = this.currpage - 1
              }
            }

            this.machineList(this.searchJsonCopy, this.currpage) // 刷新
          } else if (res == 7) {
            this.$message.error('请稍后重新获取验证码')
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      sendYZM() { // 倒计时结束后发送验证码
        const data = {
          phone: this.Moblie,
          type: 3 //	短信类型 ( 1老师修改手机号 3代理解除设备绑定 )
        }
        // 原来sendSms(data).then(res => { // 重新开始倒计时
        sendSmsNew(data).then(res => { // 重新开始倒计时
          if (res.status.code == 1) {
            this.$message({
              message: '发送成功',
              type: 'success'
            })
            this.YZMsend()
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      YZMsend() { // 验证码
        const TIME_COUNT = 60
        if (!this.timer) {
          this.count = TIME_COUNT
          this.show = false
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      },
      aaa(id) { //步骤条判断写方法
        if (id == 1) {
          alert(id)
        }
      },
      submitForm(ywtype) {
          // this.$refs[formName].validate((valid) => {
          //   if (valid) {
              if(this.ruleForm.pass !=""){

               var numReg = /^[0-9]*$/
               var numRe = new RegExp(numReg)

               if (!numRe.test(this.ruleForm.pass)) {
                      this.$message({
                        message: '密码只能是数字',
                        type: 'error'
                      })
                     return  false;
                  }
              }

              const data = {
                pass : this.ruleForm.pass,
                kd: this.ruleForm.kd,
                hg: this.ruleForm.hg,
                hg_time: this.ruleForm.hg_time,
                dly_time: this.ruleForm.dly_time,
                type: ywtype,
              }

              agentOptomPwd(data).then(res => {
                if (res.status.code == 1) {
                  this.$message({
                    message: '修改成功',
                    type: 'success'
                  })
                  if(ywtype == 1){
                    this.dialogFormVisibleygy = false;
                  }else if(ywtype == 2){
                    this.dialogFormVisibleMb = false;
                  }

                  //this.$refs[formName].resetFields();
                } else {
                  this.$message.error(res.status.msg)
                }
              }).catch(err => {
                console.log(err)
              })
          //   } else {
          //     return false;
          //   }
          // });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      machineStartFun(row) { // 设备开启 停用

          const data = {
            mtype  : row.MachineType,
            id     : row.ID,
            status : row.Status == 2 ? 1 : 2
          }

        machineStart(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            this.machineList({}, this.currpage) // 刷新
          } else {
            this.$message.error('操作失败')
          }
        }).catch(err => {
          console.log(err)
        })
      },
      jgChange(e) {
        if(e == 1){
          this.serchList.shopId = '';
        }else if(e == 2){
          this.serchList.schoolId = '';
        }else if(e == 3){
          this.serchList.schoolId = '';
        }
      },
      xxChange(e) {
        this.serchList.schoolId = e
        this.serchList.jgType = ''
        this.serchList.jgType = '1'
      },
      shopChange(e) {
        this.serchList.shopId = e
        this.serchList.jgType = ''
        this.serchList.jgType = '2'
      },
      zzdChange(e) {
        this.serchList.shopId = e
        this.serchList.jgType = ''
        this.serchList.jgType = '3'
      },
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    // background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
